import React, { useEffect, useState } from "react";
import ExcelExport from "../../utils/exports/gestionot";

function Tabla() {
  return (
      <ExcelExport/>
  );
}

export default Tabla;
