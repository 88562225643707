import { createSvgIcon } from '@mui/material/utils';

export const Tablero = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    //viewBox="0 0 20 20"
    fill="currentColor"
    width="25"
    height="24"
    version="1.1"
    viewBox="0 0 65.089 84.878"
    xmlSpace="preserve"
  >
    <g transform="translate(854.565 9.391)">
      <path
        fill="currentColor"
        strokeWidth="0.265"
        d="M-854.388 75.365c-.097-.098-.177-19.208-.177-42.467v-42.29h65.089l-.067 42.4-.067 42.4-32.3.067c-17.765.037-32.38-.013-32.478-.11zm59.883-42.423v-37.57h-55.033v12.7H-844.51V.397h44.98v65.088h-44.98v-7.673H-849.538v12.7h55.033zm-9.79 0V5.425h-35.189V60.46h35.19zm-28.133 21.784c-.097-.097-.177-1.169-.177-2.381V50.14h21.167v4.762h-10.407c-5.724 0-10.486-.079-10.583-.176zm-.177-11.576c0-1.08.073-2.151.161-2.381.143-.372 1.323-.419 10.583-.419h10.423V45.112h-21.167zm10.584-10.217a93.663 93.663 0 00-1.968-.857c-.136-.05.304-1.455.977-3.12a454.52 454.52 0 001.397-3.482c.16-.418-.177-.462-4.073-.529l-4.247-.073.882-1.984c.485-1.092 1.804-4.16 2.931-6.82 2.314-5.46 1.827-5.08 4.814-3.74.917.411 1.668.806 1.668.876 0 .07-.642 1.616-1.426 3.435-.785 1.82-1.36 3.374-1.28 3.455.082.082 1.914.148 4.073.148 2.482 0 3.925.099 3.925.269 0 .25-.548 1.61-4.021 9.984-.74 1.782-1.477 3.232-1.639 3.222-.161-.01-1.067-.363-2.013-.784zm-22.49 15.487v-4.365H-849.538v8.731H-844.51zm0-15.478v-6.085H-849.538v12.17H-844.51zm0-15.478v-4.366H-849.538v8.732H-844.51z"
      ></path>
    </g>

  </svg>,
  'Bell'
);
